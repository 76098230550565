import React from 'react';

export const heroLogoData = [
  {
    id: 0,
    src: 'finsall.png',
    width: '282',
    height: '89',
    className: 'w-[94px]',
    alt: 'Finsall',
  },
  {
    id: 1,
    src: 'branch.png',
    width: '267',
    height: '100',
    className: 'w-[89px]',
    alt: 'Branch',
  },
  {
    id: 2,
    src: 'acko.png',
    width: '180',
    height: '58',
    className: 'w-[60px]',
    alt: 'Acko',
  },
  {
    id: 3,
    src: 'faircent.png',
    width: '290',
    height: '52',
    className: 'w-[96px]',
    alt: 'Faircent',
  },
];

export const heroSliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,
  variableWidth: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: heroLogoData.length - 2,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
  ],
};

export const enachBenifits = [
  {
    id: 0,
    icon: '/img/enach/nbfcs.svg',
    heading: 'NBFCs and digital lenders',
    description: (
      <>
        Automate your loan recollection by creating{' '}
        <h2 className="inline font-body">e mandates</h2> upto Rs.10 Lakhs per
        EMI. Send an auto-reminder to ensure your borrowers do not miss the EMI
        or default their payments. Set up daily debits for repayment of
        micro-loans.
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/enach/mutual-funds.svg',
    heading: 'Mutual Funds and SIP platforms',
    description:
      'Guarantee uninterrupted investments to your customers through eNACH on any of the investment instruments like Mutual Funds, equity schemes, etc.',
  },
  {
    id: 2,
    icon: '/img/enach/insurance.svg',
    heading: 'Insurance providers',
    description: (
      <>
        Set up <h2 className="inline font-body">eNACH</h2> based e mandates for
        collecting insurance premiums on a monthly, quarterly, half-yearly or
        yearly frequency.
      </>
    ),
  },

  {
    id: 3,
    icon: '/img/enach/edtech.svg',
    heading: 'Ed-tech startups and ERP solutions',
    description:
      'Get more learners to easily subscribe to your online courses by providing eNACH on your checkout. Enable parents and students to pay school fees via auto-debit right from your institute’s website.',
  },
  {
    id: 4,
    icon: '/img/enach/saas.svg',
    heading: 'SaaS businesses',
    description:
      'Grow your subscription business effortlessly by creating customized payment plans for your product or service. Set rules to auto-deduct the plan amount via e mandate on-demand, anytime based on service usage or debit the amount periodically.',
  },
];

export const enachSolutions = [
  {
    id: 0,
    title: 'eNACH',
    image: {
      src: '/img/enach/enach.png',
      width: 954,
      height: 748,
      style: { maxWidth: 517 },
    },
    checkList: [
      { id: 0, text: 'Fastest way to create and authorize recurring payments' },
      { id: 1, text: 'Same day mandate confirmation by the bank' },
      {
        id: 2,
        text:
          'Superior, custom-branded checkout experience with high success rates on mandate creation',
      },
      {
        id: 3,
        text:
          'Allow payment retries and send auto-reminders for on time payment debit',
      },
    ],
    href:
      'https://www.cashfree.com/contact-sales?source-action=eNACH&action=Contact%20Sales&button-id=ContactSales_eNACH',
  },
  {
    id: 1,
    title: 'Physical NACH',
    image: {
      src: '/img/enach/physical-enach.png',
      width: 992,
      height: 720,
      style: { maxWidth: 534 },
    },
    checkList: [
      {
        id: 0,
        text:
          'Provide the convenience of auto-debit to customers who do not have access to netbanking or debit card credentials',
      },
      {
        id: 1,
        text:
          'Expand your recurring payments business to tier-2/tier-3 markets with lower digital adoption',
      },
      {
        id: 2,
        text:
          'Set up easy loan recollection with just a one-time signature approval from your borrowers',
      },
    ],
    href:
      'https://www.cashfree.com/contact-sales?source-action=eNACH&action=Contact%20Sales&button-id=ContactSales_PhysicalNACH',
  },
];

export const enachRecurringPayments = [
  {
    id: 0,
    icon: '/img/enach/merchants.svg',
    title: 'Merchants',
    checkList: [
      {
        id: 0,
        text: (
          <>
            <b className="block mb-1">Customer retention</b>
            <span className="text-2sm md:leading-6">
              With just one-time digital authentication, eNACH allows you to
              auto-debit your customer&apos;s account at the start of every
              billing&nbsp;cycle.
            </span>
          </>
        ),
      },
      {
        id: 1,
        text: (
          <>
            <b className="block mb-1">Reduced operational costs</b>
            <span className="text-2sm md:leading-6">
              eNACH mandate drastically reduces the cost related to invoicing
              and having to keep up and chase late payments from&nbsp;customers.
            </span>
          </>
        ),
      },
      {
        id: 2,
        text: (
          <>
            <b className="block mb-1">Security and Compliance</b>
            <span className="text-2sm md:leading-6">
              Your customer&apos;s data is safe, as the PCI compliance will be
              strictly managed by your payment solutions&nbsp;provider.
            </span>
          </>
        ),
      },
    ],
  },
  {
    id: 1,
    icon: '/img/enach/customers.svg',
    title: 'Customers',
    checkList: [
      {
        id: 1,
        text: (
          <>
            <b className="block mb-1">Simplicity</b>
            <span className="text-2sm md:leading-6">
              Authorizing an eNACH mandate is a simple process. All you need is
              your Net Banking credentials or debit card details, and
              you&apos;re good to&nbsp;go.
            </span>
          </>
        ),
      },
      {
        id: 2,
        text: (
          <>
            <b className="block mb-1">Uninterrupted service</b>
            <span className="text-2sm md:leading-6">
              No constant payment reminders, authenticate your subscription once
              and enjoy uninterrupted service&nbsp;throughout.
            </span>
          </>
        ),
      },
      {
        id: 3,
        text: (
          <>
            <b className="block mb-1">Flexibility</b>
            <span className="text-2sm md:leading-6">
              With a variety of options, inclusions and easy termination, it
              provides an optimal user&nbsp;experience.
            </span>
          </>
        ),
      },
    ],
  },
];

export const faqData = [
  {
    id: 0,
    q: (
      <div>
        Which banks are supported for{' '}
        <h2 className="inline font-body">eNACH payment mode</h2>?
      </div>
    ),
    a: (
      <p>
        You can find the list of banks supported for E-mandate{' '}
        <a
          href="https://docs.cashfree.com/docs/create-manage-subscription"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          here
        </a>
        . There is no limitation on the number of banks supported by Cashfree
        Payments. We support all the banks supported by NPCI.
      </p>
    ),
  },
  {
    id: 1,
    q:
      'What is the difference between the two authentication modes - “Net Banking” and “Debit card” under eNACH payment mode?',
    a: (
      <p>
        The corresponding bank authenticates the end-customer by asking them to
        enter their net banking credentials or their debit card credentials.
        Here, the debit card credentials are only used to authenticate the
        customer. This ensures, the e-mandate continues uninterrupted even in
        the case of debit card expiry during the mandate tenure.
      </p>
    ),
  },
  {
    id: 2,
    q: 'Is there a maximum amount limit per debit request for eNACH?',
    a: <p>The maximum amount limit set for eNACH mandate is ₹10,00,000.</p>,
  },
  {
    id: 3,
    q: 'Can my customer cancel a Subscription that is authorized via eNACH?',
    a: (
      <p>
        The banks maintain all the e-mandates created by their customers. In
        order to cancel the mandate the customer can reach out to their bank.
      </p>
    ),
  },
  {
    id: 4,
    q: 'Are Current accounts supported in case of eNACH mandate?',
    a: <p>Yes.</p>,
  },
  {
    id: 5,
    q: (
      <div>
        What are the charges imposed on my customers while{' '}
        <h2 className="inline font-body pl-1">Mandate Creation</h2>?
      </div>
    ),
    a: (
      <div>
        A few banks charge the end-customer for the creation of the mandate.
        This charge, however, is not imposed by Cashfree Payments.
        <ul className="list-disc">
          <li>SBI - 58/-</li>
          <li>BOB - 116/-</li>
        </ul>
      </div>
    ),
  },
  {
    id: 6,
    q: 'What is a Utility Code?',
    a: (
      <>
        <p>
          Utility Code or Corporate User ID is a User Identification Number that
          is allotted by NPCI. The Utility Code helps to identify the business
          in whose favor the mandate is being issued. A mandate request cannot
          be initiated without a Utility Code in the Mandate Management System.
        </p>
      </>
    ),
  },
];
export const otherProducts = [
  {
    id: 0,
    title: 'UPI AutoPay',
    body: (
      <>
        Collect recurring payments upto ₹15,000 instantly through mandates
        created via any UPI app. Reduce loss of revenue with 30% higher success
        rates on mandate&nbsp;creation.
      </>
    ),
    url: 'https://www.cashfree.com/upi-autopay/',
    iconname: '/img/icons/upi-autopay.svg',
  },
  {
    id: 1,
    title: 'Payment Gateway',
    body: (
      <>
        Collect payments on your website or app. Verify account details before
        doing customer&nbsp;refund.
      </>
    ),
    url: 'https://www.cashfree.com/payment-gateway-india/',
    iconname: '/img/icons/payment-gateway-2.svg',
  },
  {
    id: 2,
    title: 'Easy Split',
    body: (
      <>
        Verify and onboard vendors, collect payments from customers, deduct
        commissions, and split payments between vendors or your own
        bank&nbsp;accounts.
      </>
    ),
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway/',
    iconname: '/img/icons/easy-split.svg',
  },
  {
    id: 3,
    title: 'Payouts',
    body: (
      <>
        Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm
        instantly even on a bank&nbsp;holiday.
      </>
    ),
    url: 'https://www.cashfree.com/payouts/',
    iconname: '/img/icons/payouts-green.svg',
  },
];
