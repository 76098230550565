import React, { useRef } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import OtherProducts from '../components/OtherProducts/OtherProducts';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import CheckList from '../components/CheckList/CheckList';
import {
  enachBenifits,
  enachRecurringPayments,
  enachSolutions,
  faqData,
  heroLogoData,
  heroSliderSettings,
  otherProducts,
} from '../content/enach';
import '../styles/enach.scss';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';

function EnatchPage({ ...rest }) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=eNACH&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Best eNACH solution by Cashfree Payments"
        description="eNACH solution to streamline your recurring payments and subscription business. Provide fast and smooth e-mandate creation for your customers via netbanking or debit card authentication."
        url="https://www.cashfree.com/enach/"
        keywords={[
          'e nach',
          'eNach',
          'eNach mandates',
          'Nach eMandates',
          'e nach registration',
          'enach registration',
        ]}
      />
      <section
        className="relative section-padding bg-cf-primary enach-hero-section  overflow-hidden text-white"
        ref={container}>
        <div className="container relative">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:mb-[20px]">
            <div className="relative z-[1] w-full lg:mt-[70px]">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                eNACH
              </h2>
              <div className="mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[718px] font-heading">
                Collect recurring payments easily through eNACH
              </div>
              <div className="text-opacity-80 mb-5 md:mb-10 max-w-[620px] text-[16px] leading-[24px] md:text-2.5md">
                Streamline recurring payments and subscriptions by providing
                fast and smooth{' '}
                <h2 className="inline font-body">mandate creation</h2> for your
                customers or borrowers via eNACH.
              </div>

              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start mb-6 lg:mb-10">
                <a
                  className="button button-green w-full md:w-auto md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto mt-4 md:mt-0"
                  href="https://www.cashfree.com/contact-sales?source-action=eNACH&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="relative z-[1] w-full md:w-[70%] lg:self-center xl:self-start">
              <div className="mx-auto lg:-mt-10">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/enach/poster.png"
                  width="1280"
                  height="1097"
                  mp4Src="/img/enach/hero-video.mp4"
                  webmSrc="/img/enach/hero-video.webm"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="logo-container mt-12 lg:mt-[76px]">
          <div className="relative overflow-hidden z-10">
            <HeroLogoSlider
              data={heroLogoData}
              imgUrl="/img/slider-logo"
              className="pl-[10px]"
              itemClassName="mx-[25px] md:mx-[36px] flex items-center align-center h-[48px]"
              settings={heroSliderSettings}
            />
          </div>
        </section>
      </section>
      <section className="p-section-2 overflow-hidden">
        <div className="container">
          <div className="text-sm font-semibold text-cf-primary pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body md:hidden">
            eNACH
          </div>
          <div className="font-heading text-lg md:text-xl font-semibold text-left mb-4 md:mb-8 max-w-[610px]">
            Various businesses benefit from&nbsp;
            <h2 className="inline">eNACH</h2>
          </div>
          <div className="flex flex-col-reverse md:flex-col">
            <a
              className="button button-green w-full md:max-w-max mt-8 md:mt-0 mb-0 md:mb-6"
              href="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_BusinessBenefitfromeNACH">
              Create Account <span className="chevron" />
            </a>
            <div className="flex flex-wrap items-stretch mx-0 md:mx-[-19px]">
              {enachBenifits.map(({ id, description, heading, icon }) => (
                <div
                  key={id}
                  className="w-full md:w-1/3 pt-[16px] md:pt-[38px] px-0 md:px-[19px] min-h-[300px] md:min-h-0">
                  <div className="bg-cf-light-grey px-4 py-6 h-full rounded">
                    <div className="w-[32px] md:w-[40px] h-[32px] md:h-[40px] mb-4 md:mb-6">
                      <img
                        className="w-full h-full"
                        src={icon}
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div>
                      <h3 className="font-body font-bold md:font-semibold mb-2 md:mb-3 text-[16px]">
                        {heading}
                      </h3>
                      <div className="text-base">{description}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-cf-light-grey p-section-2 enach-how-does overflow-hidden">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row flex-wrap justify-between">
            <div className="w-full md:w-1/2 md:pr-[75px] image-container">
              <picture>
                <source
                  media="(min-width:768px)"
                  srcSet="/img/enach/how-work.png"
                  width="1154"
                  height="924"
                />
                <img
                  src="/img/enach/how-work-mb.png"
                  width="515"
                  height="434"
                  alt=""
                  className="w-full mx-auto md:w-auto"
                />
              </picture>
            </div>
            <div className="w-full md:w-1/2 md:self-center">
              <div className="text-2lg font-heading mb-4">
                <h2 className="inline">What is eNACH</h2> and how does it work?
              </div>
              <div className="text-md">
                eNACH, by NPCI (National Payments Corporation of India), is a
                central system that facilitates digital payments that are
                repeated or periodic in nature. eNACH helps in recurring payment
                collection for merchants from their customers via automatic
                debit from the customer’s&nbsp;account.
                <br />
                <br />
                Merchants can{' '}
                <h2 className="inline font-body">create e mandate</h2> links and
                share via Email, SMS or Whatsapp. Customers have to authorize
                the e mandate request and complete a one-time authentication by
                entering their netbanking credentials or debit card details to
                start the auto-debit over any&nbsp;frequency.
              </div>

              <a
                className="button button-green w-full md:w-auto mb-7 md:mb-0 mt-6 md:mt-8"
                href="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_WhatiseNACH">
                Create Account <span className="chevron" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-cf-dark p-section-2 enach-solutions">
        <div className="container  text-white">
          <div className="font-heading text-lg md:text-xl font-semibold text-center mb-8 md:mb-6">
            Choose the right NACH Mandate solution for your business
          </div>
          <div className="flex flex-wrap">
            {enachSolutions.map(({ image, title, checkList, id }) => (
              <div
                className="relative md:flex-row w-full flex flex-wrap enach-card group  bg-white bg-opacity-10 px-6 pt-6 md:pl-0 md:py-16 md:pr-12 mb-6 md:mb-0 last:mb-0 md:mt-10 rounded even:md:flex-row-reverse md:items-end"
                key={id}>
                <div className="md:w-7/12 md:pl-[8.35%] group-even:md:pl-[75px]">
                  <h2 className="text-4md font-semibold mb-4 md:mb-5 relative z-10 font-body">
                    {title}
                  </h2>
                  <CheckList
                    data={checkList}
                    className="mb-6 md:mb-8 relative z-10 group-odd:md:max-w-[570px] group-even:md:max-w-[535px]"
                  />
                  <a
                    className="button button-green w-full md:max-w-max relative z-10"
                    href="https://www.cashfree.com/contact-sales?source-action=nach%20mandate&action=Contact%20Sales&button-id=ContactSales_eNACH">
                    Contact sales <span className="chevron" />
                  </a>
                </div>
                <div className="md:w-5/12">
                  <img
                    alt={title}
                    {...image}
                    className="mx-auto w-full relative z-10 pt-7 md:pt-0 md:pl-[8.35%] md:-mb-16"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="relative bg-cf-light-grey p-section-2 enach-recurring-payments">
        <div className="container">
          <div className="font-heading text-lg md:text-xl font-semibold text-center mb-8 md:mb-16 md:mx-auto md:max-w-[610px]">
            The convenience of{' '}
            <h2 className="inline">eNACH for recurring payments</h2>
          </div>
          <div className="flex flex-wrap md:mx-[-19px]">
            {enachRecurringPayments.map(({ icon, checkList, title, id }) => (
              <div
                className="w-full md:w-1/2 md:px-[19px] flex pt-[24px] md:pt-0"
                key={id}>
                <div className="p-6 md:p-10 bg-cf-white">
                  <div className="w-[32px] md:w-[48px] h-[32px] md:h-[48px] mb-4">
                    <img
                      className="w-full h-full"
                      src={icon}
                      loading="lazy"
                      alt=""
                    />
                  </div>
                  <div className="text-3md mb-6 font-semibold">{title}</div>
                  <CheckList
                    data={checkList}
                    className="mb-0"
                    itemMargin="mb-3 md:mb-6"
                    fontClass="text-[16px] leading-6 md:text-2.5sm md:leading-[24px]"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="text-center">
            <a
              className="button button-green w-full md:w-auto mt-8 md:mt-10"
              href="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_eNACHforRecurringPayments">
              Create Account <span className="chevron" />
            </a>
          </div>
        </div>
      </section>
      <section>
        <div className="container general-faqs">
          <FAQ open="0" data={faqData} />
        </div>
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=eNACH&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=eNACH&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default EnatchPage;
